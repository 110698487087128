
import { defineComponent , watch , ref ,Ref , PropType , onMounted , reactive ,   } from 'vue'
import { revisaoComparacao } from '@/services/BusinessInteligence'
import { FilterProps } from '../models'
import { useStore } from 'vuex';
import ApexCharts from 'apexcharts';
import LoadingNameGrafico from '../components/LoadingNameGrafico.vue'
import { ContentLoader } from 'vue-content-loader';
import {fileName, heightDinamicoFunc, titleChart} from "@/views/business-inteligence/ultils";

export default defineComponent({
name: 'Revisao-Comparacao',
components: {
    ContentLoader,
    LoadingNameGrafico
},
props: {   
    filter: {        
        type: Object as PropType<FilterProps>,
        required: true      
    },
},
setup(prop){
    const animate = ref(true)
    const chart : any = ref(null);
    const noData = ref(false)
    const filter : Ref<FilterProps> = ref(prop.filter)
    const store = useStore();
    const dominio_gestao : string = store.getters.layoutConfig("distribuidor.distribuidor");   
    const categories : any = ref([])
    const seriesData : any = ref([])
    const chartInstance = ref();
    const options = ref();
    const concessionariaList = ref(store.getters.concessionariasList)
    const fileNameDownload = ref(fileName('REVISÃO_COMPARAÇÃO',filter.value.ano,filter.value.mes))
    const title = ref(titleChart('REVISÃO COMPARAÇÃO',filter.value.ano,filter.value.mes))

    watch(() => store.getters.concessionariasList, (newValue) => {
        concessionariaList.value = newValue
    })

    onMounted( async () => {
        
       await getFaturamentoComparacao()

       rederChart()            
    })

  const rederChart = () => {
    options.value = {
      series: seriesData.value,
      chart: {
        height: 350,
        type: 'line',
        title: {
          text: title.value,
          align: 'CENTER',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238'
          },
        },
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          },
          export: {
            png: {
              filename: fileNameDownload.value,
            },
            svg: {
              filename: fileNameDownload.value,
            }
          }
        }
      },
      dataLabels: {
        enabled: true
      },
      legend: {
        showForSingleSeries: true
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      title: {
        text: title.value,
        align: 'left'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
        padding: {
          left: 30, // Ajustar a margem esquerda
          right: 30 // Ajustar a margem direita
        }
      },
      markers: {
        size: 5
      },
      xaxis: {
        categories: categories.value
      },
      yaxis: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      },
      responsive: [{
        breakpoint: 430,
        options: {
          title: {
            text: 'REVISÃO COMPARAÇÃO',
            align: 'LEFT',
            margin: 0,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '12px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238'
            },
          },
        },
      },
      ],
    };

    chartInstance.value = new ApexCharts(chart.value, options.value);
    chartInstance.value.render();
  }
        
    watch(() => prop.filter,async (newValue) => {          
        filter.value = newValue
        fileNameDownload.value = fileName('REVISÃO_COMPARAÇÃO',filter.value.ano,filter.value.mes)
        title.value = titleChart('REVISÃO COMPARAÇÃO',filter.value.ano,filter.value.mes)

        await  getFaturamentoComparacao()
        if (chart.value) {
            
            chartInstance.value.updateOptions({
              series: seriesData.value,
              xaxis: {categories: categories.value},
              title: {
                text: title.value
              },
              chart: {
                toolbar: {
                  export: {
                    png: {
                      filename: fileNameDownload.value,
                    },
                    svg: {
                      filename: fileNameDownload.value,
                    }
                  }
                }
              },
            });           
        }
    },{deep: true})

    const getFaturamentoComparacao = async () => {
        if(filter.value.codConcessionaria?.length){  

            categories.value = []
       
            noData.value = false
            animate.value = true
           
            try{
                const response = await revisaoComparacao(filter.value) 

                noData.value =  response.length > 0 ? false : true 
                animate.value = false 
                
                const arrayApenasCodCOncessionarias = response.map((obj: any) => {
                    const novoObjeto : any = { ...obj }; // Faz uma cópia do objeto original
                    delete novoObjeto.mes; // Remove a propriedade "mes"
                    delete novoObjeto.ano; // Remove a propriedade "ano"
                    return novoObjeto;
                });            

                //logica para capturar as concesionarias que vem no response, ela pode esta apenas em um objeto a logica pega em qualquer lugar a concessionaria
                const codConcessionarias = new Set();

                arrayApenasCodCOncessionarias.forEach(obj => {
                    const keys = Object.keys(obj);
                    keys.forEach(key => codConcessionarias.add(key));
                });

                const arrayCodConcessionarias = Array.from(codConcessionarias);

                const month = response.map((item : any )=> convertMesString(item.mes))
                categories.value = month            
                
                const concessionariaSelect = arrayCodConcessionarias.map(item => {
                const concessionaria =  concessionariaList.value.filter(concessionaria => concessionaria.codConcessionaria == item)
                
                    return {
                        codConcessionaria : concessionaria[0].codConcessionaria ? concessionaria[0].codConcessionaria : concessionaria.codConcessionaria,
                        nomeFantasia: concessionaria[0].nomeFantasia
                    }
                })

                seriesData.value = concessionariaSelect.map((concessionaria : any ) => {
                    const concessionariaFilter = response.map((item : any ) => {

                        if(isNaN(item[concessionaria.codConcessionaria])){
                            return 0
                        }

                        return Number(item[concessionaria.codConcessionaria]) ?? 0
                    })

                    const soma = concessionariaFilter.reduce(function(total, valor) {
                        return total + valor;
                    }, 0);

                    return {
                        name: concessionaria.nomeFantasia,
                        data: concessionariaFilter,
                        sum: soma,
                    }
                })

                seriesData.value = seriesData.value.sort((a,b) => {
                    return b.sum - a.sum;
                })

            }catch{
                animate.value = false
            }
             
        }else{
            categories.value = []
            seriesData.value = []
        }
    }

    function convertMesString(mes : string) : string{
        const meses = {
        '01': 'Janeiro',
        '02': 'Fevereiro',
        '03': 'Março',
        '04': 'Abril',
        '05': 'Maio',
        '06': 'Junho',
        '07': 'Julho',
        '08': 'Agosto',
        '09': 'Setembro',
        '10': 'Outubro',
        '11': 'Novembro',
        '12': 'Dezembro'
        }

         return meses[mes];
    }

    return {
        dominio_gestao,
        filter,
        chart,
        categories,
        animate,
        noData
    }
}
})
