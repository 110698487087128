
import { defineComponent, watch, ref, Ref, PropType, onMounted, reactive, } from 'vue'
import { ticketMedioConsultor } from '@/services/BusinessInteligence'
import { FilterProps } from '../models'
import { useStore } from 'vuex';
import ApexCharts from 'apexcharts';
import LoadingNameGrafico from '../components/LoadingNameGrafico.vue'
import { ContentLoader } from 'vue-content-loader';
import { fileName, heightDinamicoFunc, titleChart } from '../ultils'

export default defineComponent({
    name: 'Ticket-medio-Consultor',
    components: {
        ContentLoader,
        LoadingNameGrafico
    },
    props: {
        filter: {
            type: Object as PropType<FilterProps>,
            required: true
        },
    },
    setup(prop) {
        const animate = ref(true)
        const chart: any = ref(null);
        const noData = ref(false)
        const filter: Ref<FilterProps> = ref(prop.filter)
        const store = useStore();
        const codNivel = store.getters.getCodNivel;
        const dominio_gestao: string = store.getters.layoutConfig("distribuidor.distribuidor");
        const ticketMedioConsultorData = reactive({
            codConcessionaria: 0,
            nomeFantasia: '',
            revisoes: '',
        })
        const fileNameDownload = ref(fileName('TICKET_MEDIO_POR_CONSULTOR', filter.value.ano, filter.value.mes))
        const title = ref(titleChart('TICKET MÉDIO POR CONSULTOR', filter.value.ano, filter.value.mes))

        const categories: any = ref([])
        const seriesData: any = ref([])
        const chartInstance = ref();

        onMounted(async () => {
            await getTicktMedio()
            rederChart()
        })

        const rederChart = () => {
            const options = {
                title: {
                    text: title.value,
                    align: 'CENTER',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },
                chart: {
                    type: 'bar',
                    width: '100%',
                    height: heightDinamicoFunc(categories.value, false, 17, 20).heigth,
                    toolbar: {
                        export: {
                            png: {
                                filename: fileNameDownload.value,
                            },
                            svg: {
                                filename: fileNameDownload.value,
                            }
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    offsetX: 40,
                    style: {
                        fontSize: "12px",
                        colors: ["#6c7b88"],
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: "top",
                        }
                    }
                },
                series: [
                    {
                        name: 'Faturamento',
                        data: seriesData.value,
                    },
                ],
                xaxis: {
                    labels: {
                        show: false, // Oculta os rótulos do eixo x
                        maxHeight: 2,

                    },
                    categories: categories.value,
                },
                yaxis: {
                    labels: {
                        show: true,
                        align: 'right',
                        minWidth: 270,
                        maxWidth: 270,
                    },

                },
                tooltip: {
                    enabled: true,
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        const currentData = ticketMedioConsultorData[dataPointIndex]

                        const valor = currentData.valor?.replace(",", "."); // Converte para número, ou assume 0 se não for um número válido
                        const revisoes = Number(currentData.revisoes); // Converte para número, ou assume 1 se não for um número válido

                        const resultado = parseFloat(valor.replace('.', '')) / revisoes;

                        return `
                             <div class="card shadow-sm">
                                 <div class="fs-7 p-3 apexcharts-tooltip-title"
                                     style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: bold; color: #3F4254; background: #F5F8FA !important;">
                                     ${currentData.consultor}
                                 </div>
                                 <div class="p-3">
                                     <div class="fs-7">Ticket médio Valor: <span style="font-weight: 600">${resultado.toFixed(0)}  </span></div> 
                                     <div class="fs-7">Revisões:
                                         <span style="font-weight: 600"> ${currentData.revisoes} </span>
                                     </div>
                                     <div class="fs-7 mt-4">Valor Total:
                                         <span style="font-weight: 600"> R$ ${currentData.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                                     </div>
                                 </div>
                             </div>`
                    }
                },
                responsive: [{
                    breakpoint: 430,
                    options: {
                        title: {
                            text: 'TICKET MÉDIO POR VALOR TOTAL',
                            align: 'LEFT',
                            margin: 0,
                            offsetX: -40,
                            offsetY: 0,
                            floating: false,
                            style: {
                                fontSize: '12px',
                                fontWeight: 'bold',
                                fontFamily: undefined,
                                color: '#263238'
                            },
                        },
                        dataLabels: {
                            offsetX: 60,
                            enabled: true,
                            style: {
                                fontSize: "10px",
                                colors: ["#6c7b88"],
                            },
                        },
                        yaxis: {
                            labels: {
                                show: true,
                                align: "right",
                                minWidth: 0,
                                maxWidth: 120,
                                style: {
                                    fontSize: '10px',
                                },
                            },
                        },
                    },
                },
                ],
            };

            chartInstance.value = new ApexCharts(chart.value, options);
            chartInstance.value.render();

            colorLabelStatic()
        }

        //logica para mudar a cor do label abaixo do nome da concessionaria 
        const colorLabelStatic = () => {

            const textElement: NodeListOf<SVGTSpanElement> = document.querySelectorAll('#ticket-medio-consultor tspan');

            textElement.forEach((textElement: SVGTSpanElement, index: number) => {
                if (index % 2 !== 0) {
                    textElement.style.fill = 'var(--cor_texto_2)'; // Defina a cor do texto 
                    textElement.style.fontStyle = 'italic'
                } else {
                    textElement.style.fontWeight = 'bold'
                }
            });
        }

        watch(() => prop.filter, async (newValue) => {
            filter.value = newValue
            fileNameDownload.value = fileName('TICKET_MEDIO_POR_VALOR_TOTAL', filter.value.ano, filter.value.mes)
            title.value = titleChart('TICKET MÉDIO POR POR VALOR TOTAL', filter.value.ano, filter.value.mes)
            console.log('entrou')
            await getTicktMedio()
            if (chart.value) {
                chartInstance.value.updateSeries([{ data: seriesData.value }]);
                chartInstance.value.updateOptions({
                    xaxis: { categories: categories.value },
                    title: {
                        text: title.value
                    },
                    chart: {
                        height: heightDinamicoFunc(categories.value, false, 17, 20).heigth,
                        toolbar: {
                            export: {
                                png: {
                                    filename: fileNameDownload.value,
                                },
                                svg: {
                                    filename: fileNameDownload.value,
                                }
                            }
                        }
                    },
                    series: [
                        {
                            name: 'Faturamento',
                            data: seriesData.value,
                        },
                    ],
                });

                colorLabelStatic()
            }
        }, { deep: true })

        const getTicktMedio = async () => {
            categories.value = []
            seriesData.value = []

            noData.value = true
            animate.value = true

            if (filter.value.codConcessionaria) {

                if (filter.value.codConcessionaria.length > 0) {
                    try {
                        const response = await ticketMedioConsultor(filter.value)
                        noData.value = response.length > 0 ? false : true
                        console.log('noData.value', noData.value)

                        animate.value = false

                        Object.assign(ticketMedioConsultorData, response)

                        response.forEach((item) => {

                            if (heightDinamicoFunc().innerWidth < 430) {
                                categories.value.push(item.consultor);
                                categories.value.push(item.nomeFantasia.split('-'))
                            } else {
                                categories.value.push(item.consultor);
                                categories.value.push([item.nomeFantasia]);
                            }


                            const valor = item.valor?.replace(",", "."); // Converte para número, ou assume 0 se não for um número válido
                            const revisoes = Number(item.revisoes); // Converte para número, ou assume 1 se não for um número válido

                            const resultado = parseFloat(valor.replace('.', '')) / revisoes;


                            seriesData.value.push( isNaN(resultado) ? 0 : Math.floor(resultado));

                        });

                    } catch {
                        animate.value = true
                    }
                }

            }

            animate.value = false
        }

        return {
            ticketMedioConsultor,
            dominio_gestao,
            filter,
            chart,
            categories,
            animate,
            noData
        }
    }
})
