
import { defineComponent, watch, ref, Ref, PropType, onMounted, reactive , onBeforeUnmount } from "vue";
import { faturamentoPorConcessionaria } from "@/services/BusinessInteligence";
import { ContentLoader } from 'vue-content-loader';
import { FilterProps } from "../models";
import { useStore } from "vuex";
import LoadingNameGrafico from '../components/LoadingNameGrafico.vue'
import ApexCharts from "apexcharts";
import {fileName, heightDinamicoFunc, titleChart} from '../ultils'

export default defineComponent({
  name: "Faturamento",
  components: {
    ContentLoader,
    LoadingNameGrafico
  },
  props: {
    filter: {
      type: Object as PropType<FilterProps>,
      required: true,
    },
  },
  setup(prop) {
    const animate = ref(true)
    const chart: any = ref(null);
    const noData = ref(false)
    const filter: Ref<FilterProps> = ref(prop.filter);
    const store = useStore();
    const codNivel = store.getters.getCodNivel;
    const dominio_gestao: string = store.getters.layoutConfig(
      "distribuidor.distribuidor"
    );
    const concessionariasFaturamento = reactive({
      codConcessionaria: 0,
      nomeFantasia: "",
      faturamento: 0,
      produtos: "",
    });
    const categories: any = ref([]);
    const seriesData: any = ref([]);
    const chartInstance = ref();
    const options = ref();
    const fileNameDownload = ref(fileName('FATURAMENTO_POR_CONCESSIONÁRIAS',filter.value.ano,filter.value.mes))
    const title = ref(titleChart('FATURAMENTO POR CONCESSIONÁRIAS',filter.value.ano,filter.value.mes))

    onMounted(async () => {
      
      if(codNivel != -1){
        await getFaturamento();
      }
      
      
      rederChart()
    })

    const rederChart = () => {
      options.value = {
        title: {
          text: title.value,
          align: 'CENTER',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238'
          },
        },
        chart: {
          type: "bar",
          width: "100%",
          height: heightDinamicoFunc(categories.value, false).heigth,
          toolbar: {
            export: {
              png: {
                filename: fileNameDownload.value,
              },
              svg: {
                filename: fileNameDownload.value,
              }
            }
          }
        },
        dataLabels: {
          offsetX: 80,
          enabled: true,
          formatter: function (value, seriesIndex) {
            const currentData = concessionariasFaturamento[seriesIndex.dataPointIndex];
            return `R$ ${value.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
            })}`;
          },
          style: {
            fontSize: "12px",
            colors: ["#6c7b88"],
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        series: [
          {
            name: "Faturamento",
            data: seriesData.value,
            color: "#67b7dc",
          },
        ],
        xaxis: {
          labels: {
            show: false, // Oculta os rótulos do eixo x
          },
          categories: categories.value,
        },
        yaxis: {
          labels: {
            show: true,
            align: "right",
            minWidth: 270,
            maxWidth: 260,
          },
        },
        tooltip: {
          enabled: true,
          custom: function ({series, seriesIndex, dataPointIndex, w}) {
            const currentData = concessionariasFaturamento[dataPointIndex];

            return `
          <div class="card shadow-sm">
            <div class="fs-7 p-3 apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: bold; color: #3F4254; background: #F5F8FA !important;">
                ${currentData.nomeFantasia}:
            </div>
            <div class="p-3">
                <div class="fs-7">
                    Faturamento: <span style="font-weight: 600">R$ ${series[seriesIndex][dataPointIndex].toLocaleString("pt-BR", {minimumFractionDigits: 2})}</span>
                </div>
                <div class="fs-7">
                    Produtos: <span style="font-weight: 600">${currentData.produtos}</span>
                </div>
            </div>
        </div>`
          },
        },
        responsive: [
          {
            breakpoint: 430,
            options: {
              title: {
                text: 'FATURAMENTO POR CONCESSIONÁRIAS',
                align: 'LEFT',
                margin: 0,
                offsetX: -40,
                offsetY: 0,
                floating: false,
                style: {
                  fontSize: '12px',
                  fontWeight: 'bold',
                  fontFamily: undefined,
                  color: '#263238'
                },
              },
              dataLabels: {
                offsetX: 60,
                enabled: true,
                style: {
                  fontSize: "10px",
                  colors: ["#6c7b88"],
                },
              },
              yaxis: {
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 120,
                  style: {
                    fontSize: '10px',
                  },
                },
              },
            },
          },
        ],
      };
    
      chartInstance.value = new ApexCharts(chart.value, options.value);
      chartInstance.value.render();

      colorLabelStatic()
    }

    //logica para mudar a cor do label abaixo do nome da concessionaria 
    const colorLabelStatic = () => {         

      const textElement : NodeListOf<SVGTSpanElement> = document.querySelectorAll('#faturamento tspan ');

      textElement.forEach((textElement: SVGTSpanElement,index: number) => {
        if (index % 2 !== 0) {
            textElement.style.fill = 'var(--cor_texto_2)'; // Defina a cor do texto 
        textElement.style.fontStyle = 'italic'
      }else{
        textElement.style.fontWeight = 'bold'
      }
      }); 
    }

    watch(() => prop.filter, async (newValue) => {
      filter.value = newValue;
      fileNameDownload.value = fileName('FATURAMENTO_POR_CONCESSIONÁRIAS',filter.value.ano,filter.value.mes)
      title.value = titleChart('FATURAMENTO POR CONCESSIONÁRIAS',filter.value.ano,filter.value.mes)

      await getFaturamento();

      if (chart.value) {
        chartInstance.value.updateOptions({
          xaxis: { categories: categories.value },
          title: {
            text: title.value
          },
          chart: {
            height: heightDinamicoFunc(categories.value,false).heigth,
            toolbar: {
              export: {
                png: {
                  filename: fileNameDownload.value,
                },
                svg: {
                  filename: fileNameDownload.value,
                }
              }
            }
          },
          series: [
            {
              name: "Faturamento",
              data: seriesData.value,
              color: "#67b7dc",
            },
         ],
        });

        colorLabelStatic()
      }
      
    },{ deep: true });

    const getFaturamento = async () => {
        
      categories.value = [];
      seriesData.value = [];  
      
      //atrasar a animação para o grafico sumir primeiro
      noData.value = false
      animate.value = true
     
      try {
        const response = await faturamentoPorConcessionaria(filter.value);
        console.log(filter.value)
        noData.value =  response.length > 0 ? false : true 
        
        animate.value = false

        Object.assign(concessionariasFaturamento, response);

        //prepara o array para o grafico
        response.forEach((item, index) => {
          let concessionaria = concessionariasFaturamento[index]        
          
          if(heightDinamicoFunc().innerWidth < 430){

            const nomeFantasia = `${item.nomeFantasia}`
            const nomeConcesionaria = nomeFantasia.split('-')

            nomeConcesionaria.push(`P:${concessionaria.produtos}`)
            categories.value.push(nomeConcesionaria);
          }else{
            categories.value.push(item.nomeFantasia);
            categories.value.push(`P:${concessionaria.produtos}`);
          }         
        
         seriesData.value.push(item.faturamento.toFixed(2));

        });
      } catch {
        animate.value = false
        noData.value = true
      }

    };   

    return {
      concessionariasFaturamento,
      dominio_gestao,
      filter,
      chart,
      categories,
      animate,
      noData
    };
  },
});
