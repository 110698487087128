
import {reactive, computed , ref, onMounted} from 'vue'
import useEmitter from '@/composables/Emmiter'
import Faturamento from '@/views/business-inteligence/Faturamento.vue'
import FaturamentoPorConcessionarias from './graficos/FaturamentoPorConcessionarias.vue';
import FaturamentoPorProduto from './graficos/FaturamentoPorProduto.vue';
import ConversaoDePacotesConsultor from './graficos/ConversaoDePacotesConsultor.vue';
import TicketMedioPorConcessionarias from './graficos/TicketMedioPorConcessionarias.vue';
import TicketMedioPorConsultor from './graficos/TicketMedioPorConsultor.vue';
import FaturamentoComparacao from './graficos/FaturamentoComparacao.vue';
import RevisaoComparacao from './graficos/RevisaoComparacao.vue';
import FaturamentoXrevisao from './graficos/FaturamentoXrevisao.vue';
import TipoDePacotes from './graficos/TipoDePacotes.vue';
import { useStore } from 'vuex';
import ConversaoDePacotes from './graficos/ConversaoDePacotes.vue';
import {dataFinalLancamento} from "@/services/BusinessInteligence";
import TicketMedioValor from './graficos/TicketMedioValor.vue';

export default {
    name: 'business-inteligence',
    components: {
        Faturamento,
        FaturamentoPorConcessionarias,
        ConversaoDePacotes,
        FaturamentoPorProduto,
        ConversaoDePacotesConsultor,
        TicketMedioPorConcessionarias,
        TicketMedioPorConsultor,
        TipoDePacotes,
        FaturamentoComparacao,
        RevisaoComparacao,
        FaturamentoXrevisao,
        TicketMedioValor
    },
    setup(){
        const usuarioLogado = computed(() => store.getters.usuarioLogado);
        const store = useStore();
        const emitter = useEmitter();
        const codNivel = store.getters.getCodNivel;
        const concessionariaList = ref(store.getters.concessionariasListAll)
        const dataFilter = ref({
            codConcessionaria: undefined,
            codGrupo: undefined,
            ano: 2023,
            mes: undefined,
            codMarca: undefined,
            userId:  usuarioLogado.value.codUsuario
        })
        const allMarcas = ref()
        const allGrupo = ref()    
        const allConsultor = ref()
        const dataAtual = new Date();
        const mesAtual = dataAtual.getMonth() + 1;    
        const nameConsultor = ref('')
        const dataFinal = reactive({
          loading : true,
          ultimaData: ''
        })

       
        emitter.on('filter-business-inteligence', (filter) => {
            dataFilter.value = {
                codConcessionaria: filter.codConcessionaria.length > 0 ? filter.codConcessionaria : undefined ,
                codGrupo: filter.codGrupo ? filter.codGrupo : undefined,
                ano: filter.ano,
                mes: filter.mes.length > 0 ? filter.mes : undefined,
                codMarca: filter.codMarca ? filter.codMarca : undefined,
                userId: filter.userId
            }
        })
        emitter.on('filter-business-allMarca-allGrupo-consultor', (data) => {
            
            allMarcas.value = data.allMarcas
            allGrupo.value = data.allCodGrupo
            allConsultor.value  = data.allConsultor
            formatMarca()  
            formatGrupo(),
            formatConcessionaria()   
            formatConsultor()
            getDataFinalLancamento()
        })

        const formatMarca = () => {
            if(allMarcas.value && dataFilter.value.codMarca){
                const marca = allMarcas.value.filter((marca) => marca.codMarca == dataFilter.value.codMarca)
                return marca[0].nome
            }

            return 'Todas'
        }

        const formatGrupo = () => {
            if(allGrupo.value){               

                    const grupo = allGrupo.value.find((grupo) => grupo.codGrupo == dataFilter.value.codGrupo)
                    return dataFilter.value.codGrupo ? grupo.nome : 'Todos'                    
            }

            return 'Todos'

        }

        const formatMes = (data) => {
            if (data && data.length > 0) {
                const menorNumero = Math.min(...data);

                const menorMesString = convertMesString(menorNumero);

                const ano = dataFilter.value?.ano;
                
                if (data.length === 1) {
                    return `${menorMesString} ${ano}`;
                } else {

                     const mes = data.sort((a, b) => a - b).map(mes => {
                        return convertMesString(mes) 
                    })

                    return `${mes.join(', ')} de ${dataFilter.value.ano}`;
                }
            }

            return undefined;
        }

        const formatConcessionaria = () => {
            concessionariaList.value = store.getters.concessionariasListAll
            if(dataFilter.value.codConcessionaria){
                const codConcessionariaSelected : any = dataFilter.value.codConcessionaria
                const ArrayNameConcessionarias = codConcessionariaSelected.map(codConcessionaria => {
                    const nomeConcessionaria = concessionariaList.value.find(concessionaria => concessionaria.codConcessionaria == codConcessionaria)
                   
                    return  nomeConcessionaria.nomeFantasia
                })

                return ArrayNameConcessionarias.join(', ')
            }

            return 'Todas'
        }

        const formatConsultor = () => {
            if (dataFilter.value.userId) {
                const consultor = allConsultor.value.find((consultor) => consultor.codUsuario === dataFilter.value.userId);

                if (consultor) {
                  return  nameConsultor.value = consultor.nome;
                }
            }

            return nameConsultor.value = 'Nenhum consultor selecionado';
        }

        function convertMesString(mes : any) : string{
            const meses = {
            '1': 'Jan',
            '2': 'Fev',
            '3': 'Mar',
            '4': 'Abr',
            '5': 'Mai',
            '6': 'Jun',
            '7': 'Jul',
            '8': 'Ago',
            '9': 'Set',
            '10': 'Out',
            '11': 'Nov',
            '12': 'Dez'
            }
            return meses[mes];
        }

        function cortarString(string) {
          if (string.length > 15) {
            return string.substring(0, 15) + "...";
          } else {
            return string;
          }
        }

        const getDataFinalLancamento = async () => {

          dataFinal.loading = true
          const response = await dataFinalLancamento(dataFilter.value)
          dataFinal.loading = false

          if(response.ultimaData){
            dataFinal.ultimaData = response.ultimaData
          }

          console.log(dataFinal.ultimaData,response.ultimaData)

        }

        return {
            dataFilter,
            formatMes,
            mesAtual,
            convertMesString,
            formatMarca,
            formatGrupo,
            formatConcessionaria,
            formatConsultor,
            codNivel,
            nameConsultor,
            dataFinal
        }
    }
}
