
import { defineComponent , watch , ref ,Ref , PropType , onMounted , reactive ,   } from 'vue'
import { comparacaoFaturamentoRevisao } from '@/services/BusinessInteligence'
import { FilterProps } from '../models'
import { useStore } from 'vuex';
import ApexCharts from 'apexcharts';
import { ContentLoader } from 'vue-content-loader';
import LoadingNameGrafico from '../components/LoadingNameGrafico.vue'
import {fileName, heightDinamicoFunc, titleChart} from "@/views/business-inteligence/ultils";

export default defineComponent({
name: 'Faturamento-X-Revisao',
components: {
    ContentLoader,
    LoadingNameGrafico
},
props: {   
    filter: {        
        type: Object as PropType<FilterProps>,
        required: true      
    },
},
setup(prop){
    const animate = ref(true)
    const chart : any = ref(null);
    const noData = ref(false)
    const filter : Ref<FilterProps> = ref(prop.filter)
    const store = useStore();
    const dominio_gestao : string = store.getters.layoutConfig("distribuidor.distribuidor");
    const categories : any = ref([])
    const seriesData : any = ref([])
    const revisoes = ref()
    const faturamento = ref()
    const chartInstance = ref();
    const options = ref();
    const concessionariaList = ref(store.getters.concessionariasList)
    const fileNameDownload = ref(fileName('FATURAMENTO_X_REVISÃO',filter.value.ano,filter.value.mes))
    const title = ref(titleChart('Faturamento x Revisões',filter.value.ano,filter.value.mes))


    watch(() => store.getters.concessionariasList, (newValue) => {
        concessionariaList.value = newValue
    })

    onMounted(async () => {

       await getFaturamentoComparacaoRevisao()

       renderChart()
    })

  const renderChart = () => {
    options.value = {
      series: seriesData.value,
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          },
          export: {
            png: {
              filename: fileNameDownload.value,
            },
            svg: {
              filename: fileNameDownload.value,
            }
          }
        }
      },
      stroke: {
        width: [0, 4]
      },
      title: {
        text: title.value
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1],
        formatter: function (val, opts) {
          if (opts.seriesIndex === 0) {
            return val.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            });
          }
          return val;
        }
      },
      labels: categories.value,
      xaxis: {},
      yaxis: {
        opposite: false,
        show: false
      },
      responsive: [{
        breakpoint: 430,
        options: {
          title: {
            text: 'FATURAMENTO X REVISÃO',
            align: 'LEFT',
            margin: 0,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '12px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: '#263238'
            },
          },
        },
      },
      ],
    };

    chartInstance.value = new ApexCharts(chart.value, options.value);
    chartInstance.value.render();
  }
        
    watch(() => prop.filter,async (newValue) => {          
        filter.value = newValue
        fileNameDownload.value = fileName('FATURAMENTO_X_REVISÃO',filter.value.ano,filter.value.mes)
        title.value = titleChart('Faturamento x Revisões',filter.value.ano,filter.value.mes)

        await  getFaturamentoComparacaoRevisao()
        if (chart.value) {

          chartInstance.value.updateOptions({
            xaxis: {categories: categories.value},
            title: {
              text: title.value
            },
            chart: {
              toolbar: {
                export: {
                  png: {
                    filename: fileNameDownload.value,
                  },
                  svg: {
                    filename: fileNameDownload.value,
                  }
                }
              }
            },
          });
          chartInstance.value.updateSeries(seriesData.value );
        }
    },{deep: true})

    const getFaturamentoComparacaoRevisao = async () => {
        if(filter.value.codConcessionaria?.length == 1){     
            categories.value = [];
       
            noData.value = false
            animate.value = true
          
            try{
                const response = await comparacaoFaturamentoRevisao(filter.value)

                noData.value =  response.length > 0 ? false : true 
                animate.value = false

                const month = response.map((item)=> convertMesString(item.mes))
                categories.value = month
                

                faturamento.value = response.map((item : any )=> isNaN(parseFloat(item.faturamento?.replace(/\./g, '').replace(',', '.'))) ? 0  : parseFloat(item.faturamento?.replace(/\./g, '').replace(',', '.')))
                revisoes.value = response.map((item : any )=> item.revisoes ?? 0)   
                
                seriesData.value =[
                    {
                        name: 'FATURAMENTO',
                        type: 'column',
                        data: faturamento.value
                    }, 
                    {
                        name: 'REVISÃO',
                        type: 'line',
                        data: revisoes.value
                    }
                ]            
            }catch{
                animate.value = false
            }
                                
        }else{
            categories.value = []
            seriesData.value = []
        }
    }

    function convertMesString(mes : string) : string{
        const meses = {
        '01': 'Janeiro',
        '02': 'Fevereiro',
        '03': 'Março',
        '04': 'Abril',
        '05': 'Maio',
        '06': 'Junho',
        '07': 'Julho',
        '08': 'Agosto',
        '09': 'Setembro',
        '10': 'Outubro',
        '11': 'Novembro',
        '12': 'Dezembro'
        }

         return meses[mes];
    }

    return {        
        dominio_gestao,
        filter,
        chart,
        categories,
        animate,
        noData
    }
}
})
