
import { defineComponent , watch , ref ,Ref , PropType , onMounted , reactive ,   } from 'vue'
import { tiposPacotes } from '@/services/BusinessInteligence'
import { FilterProps } from '../models'
import { useStore } from 'vuex';
import ApexCharts from 'apexcharts';
import { ContentLoader } from 'vue-content-loader';
import LoadingNameGrafico from '../components/LoadingNameGrafico.vue'
import {fileName, heightDinamicoFunc, convertMesString} from "@/views/business-inteligence/ultils";

export default defineComponent({
name: 'Tipo-de-pacotes',
components: {
    ContentLoader,
    LoadingNameGrafico
},
props: {    
    filter: {  
        type: Object as PropType<FilterProps>,
        required: true      
    },
},
setup(prop){
    const animate = ref(true)
    const chart : any = ref(null);
    const noData = ref(false)
    const filter : Ref<FilterProps> = ref(prop.filter)
    const store = useStore();
    const codNivel = store.getters.getCodNivel;
    const dominio_gestao : string = store.getters.layoutConfig("distribuidor.distribuidor");
    const categories : any = ref([])
    const seriesData : any = ref([])
    const chartInstance = ref();
    const fileNameDownload = ref(fileName('TIPO_DE_PACOTES',filter.value.ano,filter.value.mes))
    const title = ref(titleChart(filter.value.ano,filter.value.mes))


    function titleChart( ano: number, mes: string[] | undefined){
      let titleChart;
      if(mes){
        const mesString = mes.map(mes => convertMesString(mes))
        titleChart = `${ano} - ${mesString.join(' - ').toString()} `
      }else{
        titleChart = `${ano}`
      }

      return titleChart
    }


    onMounted( async () => {
      
      if(codNivel != -1){
        await getTipoPacotes()
      }

      const options = {
        title: {
          text: ['TIPO DE PACOTES',titleChart(filter.value.ano,filter.value.mes)],
          align: 'CENTER',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238'
          },
        },
        series: seriesData.value,
        chart: {
          width: 400,
          type: 'pie',
          toolbar: {
            show: true,
            tools:{
              download: true
            },
            export: {
              png: {
                filename: fileNameDownload.value,
              },
              svg: {
                filename: fileNameDownload.value,
              }
            }
          }
        },
        legend: {
          position: 'bottom',
        },
        labels: categories.value,
        responsive: [{
          breakpoint: 430,
          options: {
            title: {
              text: ['TIPOS DE PACOTES',titleChart(filter.value.ano,filter.value.mes)],
              align: 'center',
              margin: 0,
              offsetY: 0,
              floating: false,
              style: {
                fontSize: '16px',
                fontWeight: 'bold',
                fontFamily: undefined,
                color: '#263238'
              },
            },
            chart: {
              width: 400
            }
          }
        }]
      };
      chartInstance.value = new ApexCharts(chart.value, options);
      chartInstance.value.render();
    }),
        
    watch(() => prop.filter,async (newValue) => {          
        filter.value = newValue
        fileNameDownload.value = fileName('TIPO_DE_PACOTES',filter.value.ano,filter.value.mes)
        title.value = titleChart(filter.value.ano,filter.value.mes)

        await  getTipoPacotes()
        if (chart.value) {
          chartInstance.value.updateOptions({
            series: seriesData.value,
            labels: categories.value,
            title: {
              text: ['TIPO DE PACOTES',titleChart(filter.value.ano,filter.value.mes)]
            },
            chart: {
              toolbar: {
                export: {
                  png: {
                    filename: fileNameDownload.value,
                  },
                  svg: {
                    filename: fileNameDownload.value,
                  }
                }
              }
            },
          });
        }
    },{deep: true})

    const getTipoPacotes = async () => {

        categories.value = []
        seriesData.value = []

        //atrasar a animação para o grafico sumir primeiro
        noData.value = false
        animate.value = true       

        try{
            const response = await tiposPacotes(filter.value)  

            const allValuesAreZero = response.every(item => item.valor === 0);
            noData.value = allValuesAreZero;
            animate.value = false     

            response.forEach((item ) => {     
                categories.value.push(item.pacote);
                seriesData.value.push(item.valor);
            })

            if(allValuesAreZero){
                categories.value = []
                seriesData.value = []
            }

        }catch{
            noData.value = true
            animate.value = false
        }
    }

    return {
        dominio_gestao,
        filter,
        chart,
        categories,
        animate,
        noData
    }
}
})
