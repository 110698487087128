
import { defineComponent , watch , ref ,Ref , PropType , onMounted , reactive ,   } from 'vue'
import { ticketMedio } from '@/services/BusinessInteligence'
import { FilterProps } from '../models'
import { useStore } from 'vuex';
import ApexCharts from 'apexcharts';
import { ContentLoader } from 'vue-content-loader';
import LoadingNameGrafico from '../components/LoadingNameGrafico.vue'
import {fileName, heightDinamicoFunc, titleChart} from '../ultils'

export default defineComponent({
name: 'Ticket-medio-Concessionaria',
components: {
    ContentLoader,
    LoadingNameGrafico
},
props: {    
    filter: {  
        type: Object as PropType<FilterProps>,
        required: true      
    },
},
setup(prop){
    const animate = ref(true)
    const chart : any = ref(null);
    const noData = ref(false)
    const filter : Ref<FilterProps> = ref(prop.filter)
    const store = useStore();
    const codNivel = store.getters.getCodNivel;
    const dominio_gestao : string = store.getters.layoutConfig("distribuidor.distribuidor");
    const concessionariasTicketMedio = reactive({
        codConcessionaria : 0,
        nomeFantasia : '',
        revisoes: '',    
    })
    const colorBar = ref({})
    const fileNameDownload = ref(fileName('TICKET_MEDIO_POR_CONCESSIONARIA',filter.value.ano,filter.value.mes))
    const title = ref(titleChart('TICKET MÉDIO POR CONCESSIONÁRIA',filter.value.ano,filter.value.mes))
    const barigui = ref(false)
    
    const categories : any = ref([])
    const seriesData : any = ref([])
    const chartInstance = ref();

    onMounted( async () => {

      if(codNivel != -1){
        await getTicktMedio()
      }
      
      colorBarFunc()
      rederChart()            
    })

    const rederChart = () => {
      const options = {
        title: {
          text: title.value,
          align: 'CENTER',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238'
          },
        },
        chart: {
          type: 'bar',
          width: '100%',
          height: heightDinamicoFunc(categories.value, false, 20).heigth,
          toolbar: {
            export: {
              png: {
                filename: fileNameDownload.value,
              },
              svg: {
                filename: fileNameDownload.value,
              }
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: 40,
          style: {
            fontSize: "12px",
            colors: ["#6c7b88"],
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
            colors: {
              ranges: colorBar.value,
            }
          }
        },
        series: [
          {
            name: 'Faturamento',
            data: seriesData.value,
          },
        ],
        xaxis: {
          labels: {
            show: false, // Oculta os rótulos do eixo x
          },
          categories: categories.value,
        },
        yaxis: {
          labels: {
            maxHeight: 120,
            show: true,
            align: 'right',
            minWidth: 260,
            maxWidth: 270
          },
        },
        tooltip: {
          enabled: true,
          custom: function ({series, seriesIndex, dataPointIndex, w}) {
            const currentData = concessionariasTicketMedio[dataPointIndex]

            return `
                            <div class="card shadow-sm">
                                <div class="fs-7 p-3 apexcharts-tooltip-title"
                                    style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: bold; color: #3F4254; background: #F5F8FA !important;">
                                    ${currentData.nomeFantasia}
                                </div>
                                <div class="p-3">
                                    <div class="fs-7">Ticket médio: <span style="font-weight: 600">${currentData.ticketMedio}</span></div>
                                    <div class="fs-7">Ticket Médio valor:
                                        <span style="font-weight: 600"> R$ ${currentData.valorTicketMedio.toLocaleString('pt-BR', {minimumFractionDigits: 2})}</span>
                                    </div>
                                    <div class="fs-7 mt-4">Produtos:
                                        <span style="font-weight: 600"> ${currentData.produtos} </span>
                                    </div>
                                    <div class="fs-7">Revisões:
                                        <span style="font-weight: 600"> ${currentData.revisoes} </span>
                                    </div>
                                    <div class="fs-7 mt-4">Valor Total:
                                        <span style="font-weight: 600"> R$ ${currentData.valor.toLocaleString('pt-BR', {minimumFractionDigits: 2})}</span>
                                    </div>
                                </div>
                            </div>`
          }
        },
        responsive: [{
          breakpoint: 430,
          options: {
            title: {
              text: 'Ticket Medio Por Concessionaria',
              align: 'LEFT',
              margin: 0,
              offsetX: -40,
              offsetY: 0,
              floating: false,
              style: {
                fontSize: '12px',
                fontWeight: 'bold',
                fontFamily: undefined,
                color: '#263238'
              },
            },
            dataLabels: {
              offsetX: 60,
              enabled: true,
              style: {
                fontSize: "10px",
                colors: ["#6c7b88"],
              },
            },
            yaxis: {
              labels: {
                show: true,
                align: "right",
                minWidth: seriesData.value.length > 0 ? 270 : 0,
                maxWidth: 120,
                style: {
                  fontSize: '10px',
                },
              },
            }
          },
        },
        ],
      };

        chartInstance.value = new ApexCharts(chart.value, options);
        chartInstance.value.render();

        colorLabelStatic()
    }

    const colorBarFunc = () => {
      
      if(barigui.value  && (dominio_gestao == 'atria' || dominio_gestao == 'localhost' || dominio_gestao == 'homologacao')){
        colorBar.value = [
            {
                from: 2.5,
                to: 100,
                color: '#0BB783'
            },
            {
                from: 2,
                to: 2.5,
                color: '#FFB822'
            },
            {
                from: 0,
                to: 1.99,
                color: '#F64E60'
            }
        ]
    }else{
        colorBar.value = [
            {
                from: 2,
                to: 100,
                color: '#0BB783'
            },
            {
                from: 1,
                to: 2,
                color: '#FFB822'
            },
            {
                from: 0,
                to: 1,
                color: '#F64E60'
            }
        ]
    }
    }

    //logica para mudar a cor do label abaixo do nome da concessionaria 
    const colorLabelStatic = () => {         

         const textElement : NodeListOf<SVGTSpanElement> = document.querySelectorAll('#ticket-medio-chart tspan ');

        textElement.forEach((textElement: SVGTSpanElement,index: number) => {
            if (index % 2 !== 0) {
                textElement.style.fill = 'var(--cor_texto_2)'; // Defina a cor do texto 
              textElement.style.fontStyle = 'italic'
            }else{
              textElement.style.fontWeight = 'bold'
            }
        }); 
    }
        
    watch(() => prop.filter,async (newValue) => {     
      
        colorBarFunc()
        filter.value = newValue
        fileNameDownload.value = fileName('TICKET_MEDIO_POR_CONCESSIONARIA',filter.value.ano,filter.value.mes)
        title.value = titleChart('TICKET MÉDIO POR CONCESSIONÁRIA',filter.value.ano,filter.value.mes)        

        await  getTicktMedio()
        if (chart.value) {
          chartInstance.value.updateOptions({
            xaxis: {categories: categories.value},
            title: {
              text: title.value
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: "top",
                },
                colors: {
                  ranges: colorBar.value,
                }
              }
            },
            chart: {
              height: heightDinamicoFunc(categories.value, false).heigth,
              toolbar: {
                export: {
                  png: {
                    filename: fileNameDownload.value,
                  },
                  svg: {
                    filename: fileNameDownload.value,
                  }
                }
              }
            },
            series: [
              {
                name: 'Faturamento',
                data: seriesData.value,
              },
            ],
          });
            
          colorLabelStatic()
        }
    },{deep: true})

    const getTicktMedio = async () => {

        categories.value = []
        seriesData.value = []

        noData.value = false
        animate.value = true

        try {
            const response = await ticketMedio(filter.value)

            noData.value =  response.length > 0 ? false : true
            animate.value = false

            Object.assign(concessionariasTicketMedio,response)

            barigui.value = response.some(({codGrupo}) => codGrupo == 8 )

            response.forEach((item ,index) => {  
                const dateConcessionaria = concessionariasTicketMedio[index]
                let formatInfo = `TM:${dateConcessionaria.ticketMedio} - P:${dateConcessionaria.produtos} - R:${dateConcessionaria.revisoes} - VT:R$ ${dateConcessionaria.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`
                
                if (heightDinamicoFunc().innerWidth < 430) {
                    const nomeConcesionaria : any = item.nomeFantasia.split('-');
                    const array = formatInfo.split('-')
                    const primeiraMetade = array.slice(0,2);
                    const segundaMetade = array.slice(2,4);
                
                    if (nomeConcesionaria[1]) {
                        const espacos = nomeConcesionaria[1].length - nomeConcesionaria[1].replace(/ /g, '').length;
                        
                        if (espacos > 2) {
                                            
                            nomeConcesionaria.pop();
                            nomeConcesionaria.push([primeiraMetade] ,[segundaMetade])
                            categories.value.push(nomeConcesionaria);    

                        } else { 
                            nomeConcesionaria.push([primeiraMetade] ,[segundaMetade])
                            categories.value.push(nomeConcesionaria);                       
                        }
                        
                    } else {
                        nomeConcesionaria.push([primeiraMetade] ,[segundaMetade])
                            categories.value.push(nomeConcesionaria);    
                    }

            } else {
                categories.value.push(item.nomeFantasia);
                categories.value.push(formatInfo );
            }

            seriesData.value.push(parseFloat(item.ticketMedio));
            });

            colorBarFunc()
        }catch{
            animate.value = false
        }       
    }

    return {
        concessionariasTicketMedio,
        dominio_gestao,
        filter,
        chart,
        categories,
        animate,
        noData
    }
}
})
