
import { defineComponent , watch , ref ,Ref , PropType , onMounted , reactive  } from 'vue'
import { faturamento } from '@/services/BusinessInteligence'
import { FilterProps } from './models'
import ApexCharts from 'apexcharts';
import { useStore } from 'vuex';
import { ContentLoader } from 'vue-content-loader';
import useEmitter from '@/composables/Emmiter'


export default defineComponent({
    name: 'Faturamento',
    components: {
        ContentLoader
    },
    props: {
    filter: {        
        type: Object as PropType<FilterProps>,
        required: true      
    },
  },
    setup(prop){
        const filter : Ref<FilterProps> = ref(prop.filter)
        const store = useStore();
        const animate = ref(false)
        const noData = ref(false)
        const codNivel = store.getters.getCodNivel;
        const chartPie : any = ref(null);
        const dominio_gestao : string = store.getters.layoutConfig("distribuidor.distribuidor");
        const dataFaturamento = reactive({
            agregados : '',
            conversaoPacotes : '',
            faturamentoAgregados : '',
            faturamentoProdutoDistribuidor : '',
            pacotes : '' ,
            revisoes : '' ,
            ticktMedio : '',
        })
        const options = ref()
        const chartInstance = ref(); 
        const categories: any= ref([100,100])

        onMounted(async() => {
            
            if(codNivel != -1){
                await  getFaturamento()
            }

            options.value = {
                series: categories.value,
                chart: {
                width: '100%',
                type: 'pie',
                },
                legend: {
                    show: false
                },               
                tooltip: {
                    y: {
                        formatter: function(value) {
                            const formattedValue = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                            return formattedValue;
                        },
                        title: {
                            formatter: (seriesName) => seriesName == 'series-1' ? 'Faturamento Total:' : 'Faturamento c/ Produtos HML:',
                        },
                    }
                },
                colors: ['#143266', '#0BB783'],
                plotOptions: {
                    pie: {
                        offsetX: 0, // Ajuste o valor do deslocamento horizontal conforme necessário
                        offsetY: 0, // Ajuste o valor do deslocamento vertical conforme necessário
                        dataLabels: {
                            offset: -20, // Ajuste o valor negativo para mover os valores para dentro do círculo
                            minAngleToShowLabel: 10, // Ajuste o ângulo mínimo para mostrar o valor do rótulo
                            maxAngle: 360, // Ajuste o ângulo máximo para mostrar o valor do rótulo
                        },
                    },   
                }           
                // responsive: [{
                //     breakpoint: 480,
                //     options: {
                //         chart: {
                //         width: 200
                //         },
                //         legend: {
                //         position: 'bottom'
                //         }
                //     }
                // }]
            };

            chartInstance.value = new ApexCharts(chartPie.value, options.value);
            chartInstance.value.render();
        }),
            
        watch(() => prop.filter,async (newValue) => {          
            filter.value = newValue
            await getFaturamento()
            chartInstance.value.updateOptions({
              series: categories.value
            })

        },{deep: true})


        const getFaturamento = async () => {

           try{
                categories.value = []
                animate.value = true
                noData.value = false
                const response = await faturamento(filter.value)

                animate.value = false
                categories.value.push(response.faturamentoAgregados ?? 0, response.faturamentoProdutoDistribuidor ?? 0)

                Object.assign(dataFaturamento,response)
           }catch {
                animate.value = false
                noData.value = true
           }
        }

        const formatMoney = (value) => {
            return value ?  value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : '0,00';
        }

        //função para o scrool para o grafico selecionado
        const scrollIntoView = (id) => {
            const div : HTMLElement | null = window.document.getElementById(id)
            if(div){
                const yOffset = -80; // Ajuste o valor conforme necessário
                const y = div.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });    
            }       
        };

        return {
            dataFaturamento,
            dominio_gestao,
            filter,
            chartPie,
            formatMoney,
            animate,
            scrollIntoView,
            noData
        }
    }
})
