
import { defineComponent, watch, ref, Ref, PropType, onMounted, onBeforeUnmount } from "vue";
import { conversaoPacotes } from "@/services/BusinessInteligence";
import { FilterProps } from "../models";
import { useStore } from "vuex";
import ApexCharts from "apexcharts";
import LoadingNameGrafico from '../components/LoadingNameGrafico.vue'
import { ContentLoader } from 'vue-content-loader';
import {fileName, heightDinamicoFunc, titleChart} from '../ultils'

export default defineComponent({
  name: "Conversao-pacotes",
  components: {
    ContentLoader,
    LoadingNameGrafico
  },
  props: {
    filter: {
      type: Object as PropType<FilterProps>,
      required: true,
    },
  },
  setup(prop) {
    const animate = ref(true)
    const noData = ref(false)
    const chart: any = ref(null);
    const filter: Ref<FilterProps> = ref(prop.filter);
    const store = useStore();
    const codNivel = store.getters.getCodNivel;
    const dominio_gestao: string = store.getters.layoutConfig(
      "distribuidor.distribuidor"
    );    
    const existBigNameConcessionaria = ref(false)
    const categories:any = ref([]);
    const seriesData: any = ref([]);
    const chartInstance = ref();
    const fileNameDownload = ref(titleChart('CONVERSÃO_DE_PACOTES',filter.value.ano,filter.value.mes))
    const title = ref(titleChart('CONVERSÃO DE PACOTES',filter.value.ano,filter.value.mes))

    onMounted(async () => {

      if(codNivel != -1){
        await getConversaoPacotes();
      }
      
      
      rederChart()
    })

    const rederChart = () => {
      const options = {
        series: [
          {
            name: "Conversão",
            data: seriesData.value,
          },
        ],
        title: {
          text: title.value,
          align: 'CENTER',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238'
          },
        },
        chart: {
          type: "bar",
          width: "100%",
          height: existBigNameConcessionaria.value ? heightDinamicoFunc(categories.value, false, 35, 35).heigth : heightDinamicoFunc(categories.value, false, 25, 35).heigth,
          toolbar: {
            export: {
              png: {
                filename: fileNameDownload.value,
              },
              svg: {
                filename: fileNameDownload.value,
              }
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
            colors: {
              ranges: [
                {
                  from: 50,
                  to: 100,
                  color: '#0BB783',
                },
                {
                  from: 35,
                  to: 50,
                  color: '#FFB822',
                },
                {
                  from: 0,
                  to: 35,
                  color: '#F64E60',
                },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 40,
          formatter: function (value, dataPointIndex) {
            return `${value}%`;
          },
          style: {
            fontSize: "12px",
            colors: ["#6c7b88"],
          },
        },
        xaxis: {
          categories: categories.value,
          labels: {
            show: false,
            maxHeight: 2,
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: "right",
            minWidth: 270,
            maxWidth: 270,
          },
        },
        responsive: [
          {
            breakpoint: 430,
            options: {
              title: {
                text: 'CONVERSÃO DE PACOTES',
                align: 'LEFT',
                margin: 0,
                offsetX: -40,
                offsetY: 0,
                floating: false,
                style: {
                  fontSize: '12px',
                  fontWeight: 'bold',
                  fontFamily: undefined,
                  color: '#263238'
                },
              },
              dataLabels: {
                offsetX: 60,
                enabled: true,
                style: {
                  fontSize: "10px",
                  colors: ["#6c7b88"],
                },
              },
              yaxis: {
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 120,
                  style: {
                    fontSize: '10px',
                  },
                },
              },
            },
          },
        ],
      };

      chartInstance.value = new ApexCharts(chart.value, options);
      chartInstance.value.render();
    }

    watch(() => prop.filter, async (newValue) => {
        filter.value = newValue;
        fileNameDownload.value = fileName('CONVERSÃO_DE_PACOTES',filter.value.ano,filter.value.mes)
        title.value = titleChart('CONVERSÃO DE PACOTES',filter.value.ano,filter.value.mes)

        await getConversaoPacotes();
        if (chart.value) {
          chartInstance.value.updateOptions({
            xaxis: {categories: categories.value},
            title: {
              text: title.value
            },
            chart: {
              height: existBigNameConcessionaria.value ? heightDinamicoFunc(categories.value, false, 35, 35).heigth : heightDinamicoFunc(categories.value, true, 25, 35).heigth,
              toolbar: {
                export: {
                  png: {
                    filename: fileNameDownload.value,
                  },
                  svg: {
                    filename: fileNameDownload.value,
                  }
                }
              }
            },
            series: [
              {
                name: "Conversão",
                data: seriesData.value,
              },
            ],
          });
          // chartInstance.value.updateSeries([{ data: seriesData.value }]);
        }
      },
      { deep: true }
    );

    const getConversaoPacotes = async () => {

      try {
        categories.value = [];
        seriesData.value = [];

        //atrasar a animação para o grafico sumir primeiro
        noData.value = false
        animate.value = true

        const response = await conversaoPacotes(filter.value);

        noData.value =  response.length > 0 ? false : true 
        animate.value = false
        existBigNameConcessionaria.value = false
        
        response.forEach((item) => {

          if(heightDinamicoFunc().innerWidth < 430){
            
            const nomeFantasia = `${item.nomeFantasia}`
            const nomeConcesionaria = nomeFantasia.split('-')
            categories.value.push(nomeConcesionaria);
          }else{            

            if(item.nomeFantasia.includes('(')){
              const nomeConcessionaria = item.nomeFantasia.split('(')
              const localConcessionaria = nomeConcessionaria[1].replace(')','')
              categories.value.push([nomeConcessionaria[0], localConcessionaria])
              existBigNameConcessionaria.value = true
            }else{
              categories.value.push(item.nomeFantasia);
            }

          }

        seriesData.value.push(parseFloat(item.revisoes));
      });
      }catch {
        noData.value = true
        animate.value = false
      }
    };

    return {
      animate,      
      dominio_gestao,
      filter,
      chart,
      categories,      
      noData
    };
  },
});
